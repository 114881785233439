<!--<style src="./style.css"></style>-->
  <template>
    <section class="card">
      <transition name="modal" mode="in-out">
        <modal v-if="modals.show"
               :closemodal="cancelar"
               @salvar="salvar"
               :errors="this.$root.errors"
               :title="getModalTitle()">
          <template slot="body">
            <form>
              <input type="hidden" v-model="itemSelecionado[0].Id" />
              <div class="form-group">
                <label for="Descricao">Descrição</label>
                <input type="text" ref="descricao" v-focus class="form-control form-control-sm" v-model="itemSelecionado[0].Nome" id="Nome">
              </div>
              <div class="form-row">
                <div class="form-group col-md-2">
                  <label for="Ativo">Ativo</label>
                  <div class="checkbox-custom chekbox-primary">
                    <input id="Ativo" value="true" type="checkbox" v-model="itemSelecionado[0].Ativo" name="Ativo" required="">
                    <label for="Ativo"></label>
                  </div>
                </div>
              </div>
            </form>

          </template>
          <template v-slot:footer="slotFooterProps">
            <button class="btn btn-sm pull-right btn-primary modal-confirm"
                    @click="slotFooterProps.clickHandler('salvar')">
              Salvar
            </button>
          </template>
        </modal>
      </transition>
      <data-header :handleTrocarVisao="handleTrocarVisao"
                   :filtros="filtros"
                   :itemSelecionado="itemSelecionado"
                   :actionButtons="actionButtons">
        <template slot="header">
          <span class="selecionado" v-html="selecionado">
          </span>
        </template>
      </data-header>
      <div class="row main-row">
        <filtro-component :filtros="filtros"
                          :params="params"
                          :handleConsultar="handleConsultar">
        </filtro-component>
        <data-table :result="result"
                    :params="params"
                    :handleRowClick="handleRowClick"
                    :checkboxClass="resolveCheckboxClass"
                    :itemSelecionado="itemSelecionado[0]"
                    :resolveStatusClass="resolveStatusClass"
                    :handleOrdenar="handleOrdenar"
                    :selected="selected"
                    :filtros="filtros"
                    :handleConsultar="handleConsultar">
        </data-table>
      </div>
    </section>
  </template>
<!--<script>
...
</script>-->

<script>
  import consultar from '@/services/consultar.js';
  export default {
    name: 'Departamentos',
    components: {
      ...consultar.components,
    },
    data() {
      const that = this;
      return {
        ...consultar.data(),
        url: '/parametros/departamentos',
        clone: '',
        obj: {},
        actionButtons: [
          {
            text: 'Editar',
            className: 'editar padrao',
            icon: 'fas fa-edit',
            color: 'default',
            visible: true,// inicial
            visibleOnRowSelected: true,
            title: 'Editar item selecionado',
            callback: that.handleEditarButton
          },
          {
            text: '',
            className: 'novo padrao',
            icon: 'fas fa-plus',
            color: 'default',
            visible: true,
            title: 'Incluir novo departamento',
            callback: that.handleNovoButton
          },
          {
            text: '',
            className: 'excel padrao',
            icon: 'fas fa-file-excel',
            iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Exportar para excel',
            callback: that.exportar
          },
        ],
        filtros:
        {
          data: [
            {
              id: 'Nome',
              title: 'Descrição',
              active: false,
              placeholder: '',
            },
            {
              id: 'Ativo',
              title: 'Status',
              active: false,
              value: 'true',
              source: [
                {
                  id: 'Ativo',
                  title: 'Ativo',
                  value: 'true'
                },
                {
                  id: 'Ativo',
                  title: 'Inativo',
                  value: 'false'
                }
              ],
              sourceType: 'radio'
            }
          ],
          visao: [
            {
              id: 0,
              title: 'Todos departamentos',
              filtro: []
            },
            {
              id: 1,
              title: 'Departamentos Ativas',
              filtro:
                [
                  {
                    id: 'Ativo',
                    value: true,
                  },
                ]
            }
          ],
          visaoSelecionada: 0,
          visible: true
        }
      };
    },

  methods: {
        ...consultar.methods,
    },
  mounted: consultar.mounted,

    beforeMount: function () {

    }
};
</script>
